import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import Wrapper from './Wrapper';
import { SearchRounded } from '@material-ui/icons';
import { Divider, MenuItem, Select, TextField } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { groupActions } from '../../redux/actions';
import Loading from './Loading';

const SelectAndSearch = props => {
    const { search, handleSearch, handleSelect } = props;

    const ondemand_id = useSelector(state => state.user.user.id);
    const groups = useSelector(state => state.group.groups);
    const dispatch = useDispatch();

    const [groupSelected, setGroupSelected] = useState('Selecciona un grupo');

    useEffect(() => {
        if (groups === null) {
            dispatch(groupActions.getGroupsRequest({ ondemand_id }));
        }
    }, [groups]);

    const handleSelectComponent = e => {
        let result = null;
        let groupName = null;
        groups.forEach(group => {
            if (group.id === e.target.value) {
                result = group.participantes ? group.participantes : null;
                groupName = group.nombre;
            }
        });
        handleSelect(result);
        setGroupSelected(groupName);
    };

    if (groups === null) {
        return <Loading />;
    }
    // RETURN
    return (
        <Wrapper>
            <Container>
                <FakeSelect value={groupSelected} onChange={handleSelectComponent} variant="outlined" renderValue={value => `${value}`}>
                    {groups.map(group => (
                        <MenuItem value={group.id} key={`group:${group.id}`}>
                            {group.nombre}
                        </MenuItem>
                    ))}
                </FakeSelect>
                <FakeDivider orientation="vertical" flexItem />
                <FakeInput
                    value={search}
                    onChange={handleSearch}
                    type="text"
                    variant="outlined"
                    placeholder="Buscar"
                    InputProps={{
                        startAdornment: <SearchRounded color="secondary" />,
                    }}
                />
            </Container>
        </Wrapper>
    );
};

export default SelectAndSearch;

const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: stretch;
    flex-wrap: wrap;
    width: 100%;
    height: 4.25rem;
    padding: 0.5rem;
    flex-shrink: 0;
    border-radius: 1.25rem;
    border: 1px solid #a8a8a8;
    background: #fff;
`;

const FakeSelect = styled(Select)`
    display: flex;
    width: 30%;
    align-items: center;
    gap: 5rem;
    border-radius: 6.25rem;
    background: #f7f0ff;
    .MuiSelect-root .MuiSelect-select .MuiSelect-selectMenu .MuiSelect-outlined .MuiInputBase-input .MuiOutlinedInput-input {
        height: 2rem;
        padding: 0.5rem;
    }

    .MuiPaper-root .MuiMenu-paper .MuiPopover-paper .MuiPaper-elevation8 .MuiPaper-rounded {
        background-color: red;
    }

    /* .MuiList-root .MuiMenu-list {
        width: 50px;
        background-color: red;
    }

    .MuiButtonBase-root .MuiListItem-root .MuiMenuItem-root .MuiMenuItem-gutters .MuiListItem-gutters .MuiListItem-button {
        display: inline-flex;
        align-items: flex-start;
        width: 30px;
    } */
`;

const FakeDivider = styled(Divider)`
    height: 35px;
    width: calc(5%-1rem);
    margin: auto;
`;

const FakeInput = styled(TextField)`
    width: 65%;
	height: 2rem;
	border: white;
    .MuiInputBase-input {
        height: 2rem;
        padding: 1rem 0.5rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
		border: none;

		:focus-visible {
			outline: none;
		}
    }
`;
