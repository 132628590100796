import axios from "axios";

export function signInFA(user) {
	console.log('user', user)
	// return axios.post(`${process.env.REACT_APP_SISAPI}/auth/login`, user); //se deja de utiliar versión antigua del login
	return axios.post(`${process.env.REACT_APP_SISAPI_LOGIN}`, user);
}

export function signInUser(email) {
	return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/login/${email}`);
}

export async function getUsersIC(email) {
	try {
		const response = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/login/${email}`);
		return response.data
	} catch (error) {
		return {
			error
		}
	}
}

// COMPANY
export function getCompany({ ondemand_id }) {
	return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/empresa/${ondemand_id}`);
}

export function changePhotoCompany({ imagen, ondemand_id }) {
	return axios.post(
		`${process.env.REACT_APP_SISAPI_ONDEMAND}/actualizar_foto_ondemand/repositorio_id?id=${ondemand_id}`,
		{ base64_image: imagen }
	);
}
