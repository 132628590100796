import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import styled from 'styled-components';
import { ModalProvider } from '../../hooks/ModalContext';
import CustomSubjectAlabCard from './CustomSubjectAlabCard';
import InputIcon from '../../shared/common/InputIcon';
import DefaultSorter from '../../shared/common/DefaultSorter';
import NoResultsFound from '../../shared/common/NoResultsFound';
import { getAllCustomRoute } from '../../redux/api/content-creation.api';
import ActionButton from '../blog/ActionButton';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SubjectsSkt from '../subjects/SubjectsSkt';
// import DefaultStateFilter from '../filters/DefaultStateFilter';

const TabCustomRoutes = props => {
    const { user } = props;
    const ondemand_id = useSelector(state => state.user.user.id);

    let history = useHistory();

    // const estados = [
    //     { id: 0, name: 'Todas', value: 'Todas' },
    //     { id: 1, name: 'Asignadas', value: 'Asignadas' },
    //     { id: 2, name: 'No Asignadas', value: 'No Asignadas' },
    // ];

    // STATE
    const [search, setSearch] = useState('');
    const [subjects, setSubjects] = useState([]);
    const [subjectsFiltered, setSubjectsFiltered] = useState([]);
    // const [currentState, setCurrentState] = useState( { id: 0, name: 'Todas', value: 'Todas' });
    const [order, setOrder] = useState('date-desc');
    const [changeState, setChangeState] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (subjects.length === 0) {
            getRoutesByOndemandId(user.id);
        }
    }, []);

    useEffect(() => {
        if (search.length !== 0) {
            searchsubjects(subjects, search);
        } else {
            setSubjects(subjects);
            setSubjectsFiltered(subjects);
        }
    }, [search]);

    useEffect(() => {
        if (order === 'date-asc') {
            sortByIdAsc();
        } else if (order === 'date-desc') {
            sortByIdDes();
        }
    }, [order]);

    // useEffect(() => {
    //     if (currentState.name === 'Todas') {
    //         setSubjects(subjects);
    //         setSubjectsFiltered(subjects);
    //     }
    //     if (currentState.name === 'Asignadas') {
    //         setSubjectsFiltered(subjects?.filter(value => value.total_enrolled > 0));
    //     }
    //     if (currentState.name === 'No Asignadas') {
    //         // console.log('currentState', currentState);
    //         setSubjectsFiltered(subjects?.filter(value => value.total_enrolled === 0));
    //     }
    //     setChangeState(true);
    // }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
    }, [changeState]);

    // FUNCTIONS
    const getRoutesByOndemandId = async id => {
        let req = await getAllCustomRoute(id);
        console.log('req', req);
        if (req.error) {
            alert('Ocurrió un error');
        } else {
            setSubjects(req);
            setSubjectsFiltered(req);
        }
    };

    //buscador
    const handleSearch = e => {
        setSearch(e.target.value);
    };
    const searchsubjects = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.name?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setSubjectsFiltered(newArray.length === 0 ? [] : newArray);
    };

    //Filtro DefaultStateFilter
    // const handleCurrentState = newValue => {
    //     console.log('newValue', newValue);
    //     const estadoEncontrado = estados.find(estado => estado.name === newValue);
    //     if (estadoEncontrado) {
    //         setCurrentState(estadoEncontrado);
    //     }
    // };

    //orden
    const sortByIdDes = () => {
        //más reciente
        setSubjectsFiltered(
            subjects?.sort((a, b) => {
                return b.id > a.id;
            }),
        );
        setChangeState(true);
    };
    const sortByIdAsc = () => {
        //más antiguo
        setSubjectsFiltered(
            subjects?.sort((a, b) => {
                return a.id > b.id;
            }),
        );
        setChangeState(true);
    };
    const onOrdersChange = newOrders => {
        setOrder(newOrders);
    };

    const deleted = () => {
        // console.log('se validad deleted')
        history.push(`/subjects?tab=rutas_empresa`);
        // window.location.reload();
    };

    const publishedStateChanged = () => {
        // console.log('se solicito una publicacion/des');
        history.push(`/subjects?tab=rutas_empresa`);
    };


    if (!subjects || subjectsFiltered === null) {
        return <SubjectsSkt />;
    }
    return (
        <>
            <HeaderWrapper>
                <Left>
                    <InputIcon value={search} onChange={handleSearch} />
                    {/* <DefaultStateFilter currentState={currentState} handleCurrentState={handleCurrentState} estados={estados} /> */}
                </Left>
                <Rigth>
                    <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                    <ActionButton
                        title="Añadir ruta"
                        action="/new-custom-route"
                        icon={<AddRoundedIcon />}
                        bgc="var(--primary-one)"
                        textColor="#ffff"
                    />
                </Rigth>
            </HeaderWrapper>
            {subjectsFiltered.length !== 0 ? (
                <GridValues>
                    {subjectsFiltered.map(
                        value =>
                            value.id !== undefined && (
                                <ModalProvider key={value.id}>
                                    <CustomSubjectAlabCard
                                        subject={value}
                                        key={value.id}
                                        artId={value.id}
                                        ondemand_id={ondemand_id}
                                        deleted={deleted}
                                        publishedStateChanged={publishedStateChanged}
                                    />
                                </ModalProvider>
                            ),
                    )}
                </GridValues>
            ) : search.length === 0 ? (
                // && currentState === 'Todas'
                <NoResultsFound message="No poseen rutas formativas personalizadas, puedes añadir una desde el boton superior." /> //cambiar msj proponiendo la creacion de rutas?
            ) : (
                <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
            )}
        </>
    );
};

export default TabCustomRoutes;
const GridValues = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    height: auto;
    grid-gap: 1.5rem;
    justify-content: center;
    align-items: flex-start;
    justify-items: center;
    align-content: stretch;
    @media screen and (max-width: 425px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
    column-gap: 1.5rem;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        display: flex;
        flex-direction: column;
        column-gap: 1rem;
    }
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
`;

const Rigth = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;
