import { Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { createCustomResource, editCustomResource } from '../../services/resource.services';
import {
    ButtonWithBackground,
    ContainerForm,
    ContentMaterialPlanoForm,
    ButtonWithBorder,
    FormContainer,
    IndicationTitle,
    WrapperButtons,
} from '../../styled-components/forms.styled.components';
import Loading from '../../../../shared/common/Loading';
import MaterialPlanoForm from './MaterialPlanoForm';
import MessageModal from '../../../../shared/common/MessageModal';
import { ReadingFormSchema } from '../../../../utils/schemas';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Text from '../../../../shared/common/Text';

const ReadingForm = props => {
    const { customResourceToEdit = null, ondemand_id, handleState, handleLoading } = props;
    let history = useHistory();

    // resource_subtype_id: 9 PPT / 4 PDF / 6 PAG WEB / ,

    const formik = useFormik({
        initialValues: {
            publication_name: '',
            link_storage: '',
        },
    });

    //STATES
    const [Resource, setResource] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [selectedSubtype, setSelectedSubtype] = useState(null);

    //mensaje para recordar completar todos los campos del form
    const [messageOpen, setMessageOpen] = useState(false);

    //EFFECTS

    useEffect(() => {
        if (Resource !== null) {
            handleState(Resource);
        }
        //eslint-disable-next-line
    }, [Resource]);

    //FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleSubmit = async values => {
        console.log('values :>> ', values);
        handleLoading(true);

        const data = {
            name: `ALAB_${values.publication_name}`,
            publication_name: values.publication_name,
            type: 'material_plano',
            resource_subtype_id: selectedSubtype,
            category_id: 21,
            date_deadline: '2024-12-31',
            link_material: values.link_storage,
        };
        console.log('handleSubmit - data', data);
        let response =
            customResourceToEdit !== null
                ? await editCustomResource(ondemand_id, customResourceToEdit.id, data)
                : await createCustomResource(ondemand_id, data);
        console.log('response', response);
        setTimeout(() => {
            handleLoading(false);
            if (response.error) {
                setErrorOpen(true);
                setMessageError('El recurso no pudo enviarse, favor de contactarse con el administrador.');
            } else if (response.response_data.includes('ERROR')) {
                setErrorOpen(true);
                setMessageError(response.response_data);
            } else {
                setResource(response);
                handleState(response);
            }
        }, 3000);
    };

    const handleCompleted = () => {
        setMessageOpen(true);
    };

    const handleDismiss = () => {
        history.push(customResourceToEdit !== null ? '/resources' : '/new-custom-resource');
    };

    function handleClose() {
        setMessageOpen(false);
    }

    //seleccion de type de Material Plano
    function materialPlanoSelectedType(subtype) {
        console.log('materialPlanoSelectedType :>> ', subtype);
        setSelectedSubtype(subtype);
    }

    console.log('customResourceToEdit :>> ', customResourceToEdit);

    // RETURN
    if (!formik.initialValues) {
        return <Loading />;
    }
    return (
        <ContainerForm>
            {!customResourceToEdit ? (
                <IndicationTitle>Creación de recurso de lectura</IndicationTitle>
            ) : (
                <IndicationTitle>
                    <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#535353">
                        Fecha y hora de última modificación del recurso:   
                    </Text>
                    <Text fontSize="0.8rem" fontWeight="700" lineheight="1.5rem" fontcolor="#535353">
                        {customResourceToEdit?.last_change ? customResourceToEdit.last_change : '-'}
                    </Text>
                </IndicationTitle>
            )}

            <Formik
                initialValues={!!customResourceToEdit ? customResourceToEdit : formik.initialValues}
                onSubmit={handleSubmit}
                validationSchema={ReadingFormSchema}>
                {formik => (
                    <FormContainer>
                        <ContentMaterialPlanoForm>
                            <MaterialPlanoForm formik={formik} materialPlanoSelectedType={materialPlanoSelectedType} />
                        </ContentMaterialPlanoForm>

                        <WrapperButtons>
                            <ButtonWithBorder
                                disabled={!(formik.isValid && formik.dirty && selectedSubtype !== null)}
                                bordercolor={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="outlined"
                                type="button"
                                onClick={handleDismiss}>
                                Descartar Cambios
                            </ButtonWithBorder>
                            <ButtonWithBackground
                                bgcolor={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="contained"
                                type={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? 'button' : 'submit'}
                                onClick={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? handleCompleted : null}>
                                Guardar Cambios
                            </ButtonWithBackground>
                        </WrapperButtons>
                    </FormContainer>
                )}
            </Formik>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="./rediseño_2024/fallo_accion.png"
                subtitle="Recuerda que debes completar
                todos los campos para guardar
                el nuevo recurso."
            />
            <MessageModal
                isOpen={errorOpen}
                handleClose={handleCloseError}
                message="¡Ups!"
                image="./rediseño_2024/fallo_accion.png"
                subtitle={`${messageError}`}
            />
        </ContainerForm>
    );
};

export default ReadingForm;
