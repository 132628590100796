import axios from 'axios';



// //Recursos ADEN
// export async function getAllResources() {
//     const URL = `${window.location.origin}/mock/tabla_recursos.json`;
//     try {
//         const response = await axios.get(URL);
//         return response.data.response_data;
//     } catch (error) {
//         return {
//             error,
//         };
//     }
// }





//Recursos
//Visualización Todos los recursos que ofrece Aden  (propiedades a ondemand e incompany a definir)
export async function getAllResources() {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/microcontents`,);
        // const allResources = response.data.response_data.filter(x => x.personalized ===true)
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}


// get recursos filtrados por tipo de recurso
export async function getResourcesAdministrator(ic_ondemand_id, resource_type) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/ondemand/resources_administrator_alab/${ic_ondemand_id}?resource_type=${resource_type}`,);
        return response.data;
    } catch (error) {
        return {
            error,
        };
    }
}


// get recursos personalizados filtrados por tipo de recurso
export async function getPersonalizedCourses(ic_ondemand_id) {
    try {
        const response = await axios.get(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/objeto_aprendizaje/get_oa_personalizados/${ic_ondemand_id}`);
        return response.data.response_data;
    } catch (error) {
        return {
            error,
        };
    }
}

export async function patchAssignResource(body) {    
    try {
        const response = await axios.patch(`${process.env.REACT_APP_REPOURL}/v1/repo_aden/resources/courses`, body);
        return response
    } catch (error) {
        return {
            error,
        };
    }
}