import React, { useEffect } from "react";
import styled from "styled-components";
import Text from "../../../../shared/common/Text";
import Loading from "../../../../shared/common/Loading";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import CardService from "./CardService";
import { userActions } from "../../../../redux/actions";
import { Icon } from "@iconify/react/dist/iconify.js";
import { setService } from "../../api/services";

const Services = () => {
	// REDUX
	const user = useSelector(state => state.user)
	const { id } = useSelector(state => state.user.user)

	// STATE
	const [services, setServices] = useState(null)
	const [firstServices, setFirstServices] = useState(null)

	const [loading, setLoading] = useState(false)
	const [saved, setSaved] = useState(null)

	const dispatch = useDispatch()

	// EFFECTS
	useEffect(() => {
		if (user) {
			sanitizarServices(user.user)
		}
	}, []); // eslint-disable-line

	const sanitizarServices = (infoUser) => {
		const servicesInfo = {
			habilita_evento: "Eventos",
			habilita_workshop: "Biblioteca",
			habilita_directorio: "Directorio",
			habilita_networking: "Networking",
			habilita_blog_aden: "Blog ADEN",
			habilita_blog_empresa: "Blog empresa",
			enable_training_routes: "Secuencia de Ruta formativa",
			habilita_rutas_formativas: "Rutas formativas",
			habilita_cursos_empresa: "Cursos empresa",
			habilita_cursos_adicionales: "Cursos Adicionales",
			habilita_cursos_aden: "Cursos ADEN",
			habilita_desafio: "Desafíos",
			habilita_oferta_educativa: "Oferta educativa",
			habilita_actividad_correccion: "Actividades de corrección",
			habilita_enviar_certificados_auto: "Enviar certificados",
			pregunta_personalizacion_perfil: "Personalización perfil",
			habilita_vencimiento: "Vencimiento participantes"
		};

		const services = Object.keys(servicesInfo).map(key => ({
			state: infoUser[key],
			name: servicesInfo[key],
			key: key
		}));

		setServices(services);
		setFirstServices(services);
	};

	const handleClick = (key) => {
		setServices(prevServices => prevServices.map(service => {
			if (service.key === key) {
				return { ...service, state: !service.state }; // Cambia el estado del servicio correspondiente
			}
			return service; // Retorna el servicio sin cambios si la clave no coincide
		}));
	};

	const updateStates = async (oldServices, newServices) => {

		// Crear un objeto de mapeo para almacenar los estados de oldArray
		const stateMap = {};
		oldServices.forEach(obj => {
			stateMap[obj.key] = obj.state;
		});

		// Array para almacenar las diferencias encontradas
		const diferencias = [];

		setLoading(true)
		setSaved(null)

		for (let i = 0; i < newServices.length; i++) {
			const obj = newServices[i];

			// Verificar si el objeto tiene la misma clave en oldArray
			if (stateMap.hasOwnProperty(obj.key)) {
				// Verificar si los estados son diferentes
				if (stateMap[obj.key] !== obj.state) {
					// Agregar la diferencia al array de diferencias
					const req = await setService(id, { [obj.key]: obj.state });
					dispatch(userActions.setData({ [obj.key]: obj.state }));
					if (req.error) {
						alert(`No se pudo actualizar ${obj.name}`);
						setSaved(false);
						dispatch(userActions.setData({ [obj.key]: !obj.state }));
						setLoading(false);
						sanitizarServices(user.user)
					} else {
						setServices(prevServices => prevServices.map(service => {
							if (service.key === obj.key) {
								return { ...service, state: obj.state }; // Cambia el estado del servicio correspondiente
							}
							return service; // Retorna el servicio sin cambios si la clave no coincide
						}));
						setLoading(false);
						setSaved(true);
					}

					diferencias.push({
						key: obj.key,
						oldState: stateMap[obj.key],
						newState: obj.state
					});
				}
			}
		}
	};

	//RETURN
	if (!services) {
		return <Loading padding="4rem" />;
	}

	return (
		<Wrapper>
			<Text fontSize="20px" fontWeight="700" fontcolor="var(--primary-one)" component="h2">
				Tablero de preferencias
			</Text>
			<Container>
				<BodyWrapper>
					{services.slice(0, 6).map((service, index) =>
						<CardService values={service} key={index} onClick={handleClick} />)}
				</BodyWrapper>
				<BodyWrapper>
					{services.slice(6, 12).map((service, index) =>
						<CardService values={service} key={index} onClick={handleClick} />)}
				</BodyWrapper>
				<BodyWrapper>
					{services.slice(12, 17).map((service, index) =>
						<CardService values={service} key={index} onClick={handleClick} />)}
				</BodyWrapper>
				{
					(saved !== null && saved) ?
						<section>
							<Icon icon="ic:round-check" width="24px" height="24px" />
							Guardado
						</section>
						: saved === false && <section>
							<Icon icon="ic:round-clear" width="24px" height="24px" />
							Error
						</section>
				}
				<button onClick={() => {
					updateStates(firstServices, services)
					setFirstServices(services);
				}}>
					{
						loading ? "Guardando..." : "Guardar cambios"
					}
				</button>
			</Container>
		</Wrapper>
	);
};

export default Services;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2rem;
`

const Container = styled.div`
	position: relative;
	display: flex;
	justify-content: space-around;
	background-color: var(--secondary-one);
	border-radius: 30px;
	padding: 2rem 1rem;

	button {
		position: absolute;
		right: 3rem;
		bottom: 2rem;
		height: 40px;
		padding: 0 2rem;
		border-radius: 30px;
		background-color: var(--primary-one);
		color: var(--secondary-one);
		font-size: 16px;
		font-weight: 700;
	}

	section {
		position: absolute;
		right: 15rem;
		bottom: 2rem;
		height: 40px;
		padding: 0 2rem;
		color: var(--primary-one);
		font-size: 16px;
		font-weight: 700;
		text-align: center;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 1rem;
	}

	@media (max-width: 1200px) {
		flex-direction: column;
		gap: 2rem;
		padding-bottom: 6rem;
	}
`;

const BodyWrapper = styled.div`
	width: 30%;
	min-width: 378px;
	height: fit-content;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	box-shadow: 0px 12px 24px -15px #0000001A, 0px 0px 10px -6px #00000040;
	border-radius: 20px;
	background-color: #F9F9F9;
	padding-bottom: 1rem;

	@media (max-width: 1200px) {
		width: 100%;
	}

`;
