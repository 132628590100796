import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { contentActions } from '../../redux/actions';
import styled from 'styled-components';
import ModalForm from '../../shared/common/ModalForm';
import GridCards from '../content/GridCards';
import InputIcon from '../../shared/common/InputIcon';
import NoResultsFound from '../../shared/common/NoResultsFound';

const ViewSubject = props => {
    const { subject, openModal, closeModal } = props;

    // REDUX
    const dispatch = useDispatch();
    const user = useSelector(state => state.user.user);
    const content = useSelector(state => state.content.content);

    // STATE
    const [isOpen, setIsOpen] = useState(openModal);
    const [search, setSearch] = useState('');

    // EFFECTS
    useEffect(() => {
        if (content === null) {
            dispatch(contentActions.getContentRequest(user.id));
        }
    }, []);

    // FUNCTIONS
    function handleSearch(e) {
        setSearch(e.target.value);
    }

    function handleOpen() {
        setIsOpen(true);
    }

    function handleClose() {
        setIsOpen(false);
        closeModal();
        // setTimeout(() => {}, 500);
    }

    // RETURN
    return (
        <>
            <ModalFormFake isOpen={isOpen} handleClose={handleClose} title={`${subject.name}`}>
                <ModalAccordion>
                    {subject.courses.length === 0 ? (
                        <NoResultsFound message="No existen aún cursos asociados a esta ruta, añade uno." />
                    ) : (
                        <Container>
                            <Filter>
                                <InputIcon value={search} onChange={handleSearch} />
                            </Filter>
                            <GridCards content={subject.courses} themes={null} search={search} type="viewSubject" />
                        </Container>
                    )}
                </ModalAccordion>
            </ModalFormFake>
        </>
    );
};

export default ViewSubject;

const ModalFormFake = styled(ModalForm)`
    display: flex;
    padding: 0;
    overflow: hidden;
`;

const ModalAccordion = styled.div`
    padding: 1rem;
    width: 90vw;
    height: 80vh;
    overflow-y: scroll;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
`;

const Filter = styled.div`
    display: flex;
    justify-items: flex-end;
    height: 50px;
    width: 40vw;
    box-sizing: border-box;
    margin: 1rem 0;
    gap: 36px;
    text-align: end;
    align-self: flex-end;
`;
