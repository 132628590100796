import React, { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Text from '../../shared/common/Text';
import styled from 'styled-components';
import InputIcon from '../../shared/common/InputIcon';
import DefaultSorter from '../../shared/common/DefaultSorter';
import { useDispatch, useSelector } from 'react-redux';
import { contentActions } from '../../redux/actions';
import NoResultsFound from '../../shared/common/NoResultsFound';
import CheckCourseCard from './CheckCourseCard';
import Loading from '../../shared/common/Loading';
import { Button } from '@material-ui/core';
import DefaultStateFilter from '../filters/DefaultStateFilter';

const ModalCourseAggregation = props => {
    const { open, handleClose, handleCourseAggregation, changeChecked } = props;
    const user = useSelector(state => state.user.user);

    const dispatch = useDispatch();
    const content = useSelector(state => state.content.content);
    const loadingContent = useSelector(state => state.content.loadingContent);

    const courseOrigin = [
        { id: 0, name: 'ADEN', value: 'ADEN'},
        { id: 1, name: 'EMPRESA', value: 'EMPRESA' },
    ];

    // STATE
    //busquedas y orden
    const [search, setSearch] = useState('');
    const [courses, setCourses] = useState(content);
    const [coursesFiltered, setCoursesFiltered] = useState([]);
    const [currentState, setCurrentState] = useState('ADEN');
    const [order, setOrder] = useState('date-desc');
    const [changeState, setChangeState] = useState(false);

    //selección
    const [checkedItems, setCheckedItems] = useState([]);
    const [itemsToGrid, setItemsToGrid] = useState([]);

    // EFFECTS
    useEffect(() => {
        if (changeChecked.length !== 0 && checkedItems.length !== 0) {
            // Si en la modificación de la seleccion de OA se anularon todos los chequeados
            // Ante la modificación de la seleccion de OA
            // desde la grilla de visualizacion en el div de cada módulo,
            // impacta en este modal con el check de los items actualizado
            changeChecked?.forEach(item =>
                checkedItems?.filter(x => x.id === Number(item.id) && setCheckedItems([...checkedItems, { id: item.id }])),
            );
        } else {
            setCheckedItems(changeChecked);
            // si en la navegacion del formulario se cambia de tab,
            //queda establedido el estado de changeChecked
        }
    }, [changeChecked]);

    useEffect(() => {
        if (content === null) {
            dispatch(contentActions.getContentRequest(user.id));
            if (content !== null) {
                setCourses(content);
                setCoursesFiltered(content);
            }
        }
    }, []); //al abrir el modal

    useEffect(() => {
        if (content !== null) {
            setCourses(content);
            setCoursesFiltered(content);
        }
    }, [content]);

    //buscador
    useEffect(() => {
        if (search.length !== 0) {
            searchcourses(courses, search);
        } else {
            setCourses(content);
        }
    }, [search]);

    //orden por id
    useEffect(() => {
        if (order === 'date-asc') {
            sortByIdAsc();
        } else if (order === 'date-desc') {
            sortByIdDes();
        }
    }, [order]);

    //filtro por tipo de OA (CUSTOMIZADO o no)
    useEffect(() => {
        if (currentState.name === 'ADEN') {
            setCourses(content);
            setCoursesFiltered(content);
        }
        if (currentState.name === 'EMPRESA') {
            setCoursesFiltered(courses?.filter(value => value?.autor !== undefined));
        }
        setChangeState(true);
    }, [currentState]);

    useEffect(() => {
        setTimeout(() => {
            setChangeState(false);
        }, 200);
    }, [changeState]);

    //seleccionado al menos un item envía a la grilla de OA
    useEffect(() => {
        if (itemsToGrid.length > 0) {
            handleCourseAggregation(itemsToGrid);
        }
    }, [itemsToGrid]);

    // FUNCTIONS
    function handleChecked(e) {
        if (e.target.checked) {
            setCheckedItems([...checkedItems, { id: Number(e.target.name) }]);
        } else {
            setCheckedItems(checkedItems.filter(x => x.id !== Number(e.target.name)));
        }
    }

    //envío por props _ items seleccionados por id, con toda su info adicional
    const handleEstructure = () => {
        const filteredData = content.filter(oa => {
            return checkedItems.some(check => check.id === oa.id);
        });
        setItemsToGrid(filteredData);
        handleCourseAggregation(filteredData);
        handleClose();
    };

    //Búsqueda de OA por nombre_publicacion
    const handleSearch = e => {
        setSearch(e.target.value);
    };
    const searchcourses = (array, search) => {
        let newArray = [];
        array.forEach(item => {
            if (item.nombre_publicacion?.toLowerCase().includes(search.toLowerCase())) {
                newArray.push(item);
            }
        });
        setCoursesFiltered(newArray.length === 0 ? [] : newArray);
    };

    //Orden por ID de OA
    const sortByIdDes = () => {
        //más reciente
        setCoursesFiltered(
            courses?.sort((a, b) => {
                return b.id > a.id;
            }),
        );
        setChangeState(true);
    };
    const sortByIdAsc = () => {
        //más antiguo
        setCoursesFiltered(
            courses?.sort((a, b) => {
                return a.id > b.id;
            }),
        );
        setChangeState(true);
    };

    //selector de OA customizado o no
      const handleCurrentState = newValue => {
        console.log('newValue', newValue);
        const estadoEncontrado = courseOrigin.find(origin => origin.name === newValue);
        if (estadoEncontrado) {
            setCurrentState(estadoEncontrado);
        }
    };
    const onOrdersChange = newOrders => {
        setOrder(newOrders);
    };
   

    if (!coursesFiltered) {
        return <Loading />;
    }
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Container>
                <Text fontSize="1.125rem" fontWeight="700" lineheight="1.5rem" fontcolor="#000000">
                    Cursos disponibles
                </Text>
                <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#616161">
                    Selecciona los cursos que formarán parte del módulo.
                </Text>
                <HeaderWrapper>
                    <Left>
                        <InputIcon value={search} onChange={handleSearch} />
                        <DefaultStateFilter 
                        currentState={currentState} 
                        handleCurrentState={handleCurrentState} 
                        estados={courseOrigin} />
                    </Left>
                    <Rigth>
                        <DefaultSorter onOrdersChange={onOrdersChange} order={order} />
                    </Rigth>
                </HeaderWrapper>{' '}
                {coursesFiltered.length !== 0 ? (
                    <GridValues>
                        {coursesFiltered.map(value => (
                            // value.id !== undefined &&
                            <CheckCourseCard
                                value={value}
                                key={value.id}
                                artId={value.id}
                                type="customCourse"
                                handleChecked={handleChecked}
                                checked={checkedItems.some(x => x.id === value.id)}
                            />
                        ))}
                    </GridValues>
                ) : search.length === 0 && currentState === 'Todos' ? (
                    <NoResultsFound message="No existen aún cursos creados por la empresa, añade uno." />
                ) : (
                    <NoResultsFound message="No se encuentran resultados para tu búsqueda" />
                )}
                <ButtonWrapper>
                    <Button variant="contained" color="primary" size="small" disabled={checkedItems.length === 0} onClick={handleEstructure}>
                        Continuar
                    </Button>
                </ButtonWrapper>
            </Container>
        </Modal>
    );
};

export default ModalCourseAggregation;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    outline: none;
    border: none;
    background-color: #ffffff;
    border-radius: 1.25rem;
    height: 80vh;
    width: calc(60% - 4rem);
    padding: 2rem;
    overflow: hidden;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem 0;
    align-items: center;
    column-gap: 1.5rem;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
`;

const Rigth = styled.div`
    display: flex;
    flex-direction: row;
`;

const GridValues = styled.div`
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    align-items: flex-start;
    justify-items: start;
    align-content: stretch;
    gap: 1rem;
    padding:1rem;
    overflow-y: scroll;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: end;
    padding: 1rem 0 0 0;
`;
