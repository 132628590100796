import * as yup from "yup";

  export const BlogFormSchema = yup.object().shape({
    title: yup
      .string()
      .required("Título requerido")
      .max(320, "Máximo 320 caracteres"),
    preview: yup
      .string()
      .required("Descripción requerida")
      .max(320, "Máximo 320 caracteres"),
    categories: yup
      .string()
      .required("Categoría requerida"),
    tags: yup
      .string()
      .required("Tag requerido")
      .max(50, "Máximo 50 caracteres"),
  });

  export const BannerFormSchema = yup.object().shape({
    title: yup
      .string()
      .required("Título requerido")
      .max(120, "Máximo 120 caracteres"),
    description: yup
      .string()
      .required("Descripción requerida")
      .max(250, "Máximo 250 caracteres"),
    link: yup
      .string()
      .required("Enlace de redirección requerida")
      .max(250, "Máximo 250 caracteres"),
    tag: yup
      .string()
      .required("Tag requerido")
      .max(50, "Máximo 50 caracteres"),
  });


  export const PopUpFormSchema = yup.object().shape({
    title: yup
      .string()
      .required("Título requerido")
      .max(120, "Máximo 120 caracteres"),
    description: yup
      .string()
      .required("Descripción requerida")
      .max(250, "Máximo 250 caracteres"),
    tag: yup
      .string()
      .required("Tag requerido")
      .max(50, "Máximo 50 caracteres"),
  });

  export const CourseFormSchema = yup.object().shape({
    title: yup
      .string()
      .required("Nombre del curso requerido")
      .max(120, "Máximo 120 caracteres"),
    description: yup
      .string()
      .required('Descripción requerida')
      .max(250, "Máximo 250 caracteres"),
    author: yup
      .string()
      .required('Autor requerido')
      .max(50, "Máximo 50 caracteres"), 
    duration: yup
      .number()
      .required('Duración requerida'),
  });

  export const RouteFormSchema = yup.object().shape({
    title: yup
      .string()
      .required("Nombre de ruta requerido")
      .max(120, "Máximo 120 caracteres"),
    description: yup
      .string()
      .required('Descripción requerida')
      .max(250, "Máximo 250 caracteres"),
  });


  export const NameModulesFormSchema = yup.object(RouteFormSchema).shape({
    name_module: yup
    .string()
    .required('Nombre de Módulo requerido')
    .max(5, "Máximo 250 caracteres"),
  });



  export const validationSchema = yup.object().shape({
    ticketName: yup
        .string()
        .nullable()
        .required("Asunto requerido")
        .max(320, "Máximo 320 caracteres")
});

export const AudiovisualFormSchema = yup.object().shape({
  publication_name: yup
    .string()
    .required("El nombre del recurso es obligatorio")
    .max(120, "Máximo 120 caracteres"),
  link_video: yup
    .string()
    .url("Formato de URL inválido")
    .required("Este link es obligatorio")
    .max(250, "Máximo 250 caracteres"),
  video_id: yup
    .string()
    .required("El ID de video es obligatorio")
    .max(50, "Máximo 50 caracteres"),
});

export const ReadingFormSchema = yup.object().shape({
  publication_name: yup
    .string()
    .required("El nombre del recurso es obligatorio")
    .max(120, "Máximo 120 caracteres"),
  link_storage: yup
    .string()
    .url("Formato de URL inválido")
    .required("Este link es obligatorio")
    .max(250, "Máximo 250 caracteres"),
});


export const FirstSchemaEvaluation = yup.object().shape({
  publication_name: yup
    .string()
    .required("")
    .max(120, "Máximo 120 caracteres"),
  description: yup
    .string()
    .required("Este campo es obligatorio")
    .max(5000, "Máximo 5000 caracteres"),
});


export const secondSchemaEvaluation = yup.object({
      questions: yup.array().of(
        yup.object({
          description: yup
            .string()
            .required('Debes redactar la pregunta'),
          score: yup
            .number()
            .required('Puntaje requerido'),
          type: yup
            .string()
            .required('Selecciona el tipo de pregunta'),
          data_question_options: yup.array(),
        })
      )
      // .test('total-score', 'La suma de los puntajes debe ser 100', (questions) => {
      //   const totalScore = questions.reduce((acc, current) => acc + current.score, 0);
      //   return totalScore === 100;
      // }),
     });

