import { Button, Checkbox, Input, Radio, RadioGroup } from '@mui/material';
import { Form } from 'formik';
import { PatternFormat } from 'react-number-format';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import styled from 'styled-components';

//titulos y textos
export const IndicationTitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    width: 100%;
    height: 2rem;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid var(--primary-one);
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.025rem;
    text-align: left;
`;

export const IndicationSubtitle = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2rem;
    font-size: 0.8rem;
    font-weight: 700;
    line-height: 1.025rem;
    text-align: center;
`;

export const Label = styled.span`
    font-style: normal;
    font-weight: 700;
    font-size: 0.8rem;
    line-height: 1.5rem;
    letter-spacing: 0.0015em;
    color: #535353;
    text-align: left;
    display: flex;
    /* width: 100%; */
    width: ${p => (p.extension ? p.extension : '100%')};
    flex-direction: column;
    align-items: flex-start;
    padding-top: 1.5rem;
`;


export const LabelAnswers = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0.5rem 0;
`;

export const TitleOpction = styled.div`
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 1rem;
`;

export const AlertText = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    text-align: left;
    padding-top: 0.15rem;
    height: calc(100% - 0.15rem);
    font-size: 0.68rem;
    font-weight: 600;
    line-height: 1.35rem;
    letter-spacing: 0.0015em;
    color: var(--primary-one);
`;

//imágenes
export const ImageStyle = styled.img`
    width: 250px;
    height: auto;
    margin-top: 1rem;
    padding: 1rem;
`;

//contenedores
export const ContainerForm = styled.div`
    height: max-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 1rem 0;
    gap: 1rem;
    /* display: grid; */
`;

export const ContainerResourceForm = styled.div`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 1rem;
    border-radius: 20px;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0px 12px 24px -15px #0000001a;
    box-shadow: 0px 0px 10px -6px #00000040;
`;

//formularios
export const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: calc(100% - 2rem);
    padding: 1rem;
    height: 100%;
`;

export const Content = styled.div`
    display: grid;
    width: 100%;
    padding: 2rem 3rem;
    grid-template-areas: 'first second';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

export const First = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    grid-area: first;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
`;

export const Second = styled.div`
    grid-area: second;
    width: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
`;

export const ContentMaterialPlanoForm = styled.div`
    display: grid;
    width: calc(100% - 2rem);
    padding: 1rem;
    grid-template-areas: 'radiogroup second';
`;

export const EvaluationTabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: calc(100% - 2rem);
    padding: 1rem;
    /* background-color: yellow; */
    justify-content: space-between;
    align-items: start;
`;

export const EvaluationEstructureContainer = styled.div`
    width: calc(100% - 2rem);
    padding: 1rem;
    /* background-color: green; */
    gap: 1rem;
`;

//Radio Group Buttons
export const RadioGroupStyle = styled(RadioGroup)`
    span {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1rem;
        text-align: left;
    }
    padding-left: 1rem;
    /* .MuiFormControlLabel-label span{
        padding: 4px;
    } */
`;

export const RadioStyle = styled(Radio)`
    &.Mui-checked {
        color: var(--primary-one) !important;
    }
`;

export const RadioGroupResourcesContainer = styled.div`
    grid-area: radiogroup;
    /* position: relative; */
    height: max-content;
    display: flex;
    flex-direction: column;
`;

//botones
export const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 0.5rem;
`;

export const ButtonWithBackground = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        color: #ffff;
        background-color: ${p => p.bgcolor};
        padding: 0.75rem 1.5rem;
        height: calc(70px - 2rem);
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;

export const ButtonWithBorder = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: #ffff;
        color: ${p => p.bordercolor};
        border: ${p => `1px solid ${p.bordercolor}`};
        padding: 0.75rem 1.5rem;
        height: calc(70px - 2rem);
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.75rem;
            letter-spacing: 0.001em;
            line-height: 1.2rem;
            font-weight: 600;
        }
    }
`;

export const LabelCheckbox = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    gap: 2rem;

`;

export const PatternFormatStyle = styled(PatternFormat)`
    background-color: #fafafa;
    padding: 0.5;
    border: 1px solid #a8a8a8 !important;
    border-radius: 14px;
    width: calc(80px - 1rem);
    height: calc(60px - 1rem);
    text-align: center;

    :focus-visible {
        outline: none;
    }
`;

export const InputStyle = styled(Input)`
    background-color: #fafafa;
    padding-left: 1rem;
    border: 1px solid #a8a8a8 !important;
    border-radius: 14px;
    width: calc(80px - 1rem);

    /* &.MuiInputBase-root-MuiInput-root::after {
    border: 5px solid #a8a8a8 !important;
} */

    &.MuiInput-underline:before {
        border-bottom: none;
    }
    &.MuiInput-underline:after {
        border-bottom: none;
    }
    &.MuiInput-underline:hover:not(.Mui-disabled):before {
        border-bottom: none;
    }
    &.MuiSelect-root .MuiSelect-select .MuiSelect-selectMenu .MuiInputBase-input .MuiInput-input {
        background-color: red;
        /* height: calc(50px - 2rem);
    padding: 1rem;
    justify-content: center; */
    }

    /* &.MuiInputBase-root-MuiInput-root::before {
    border: 5px solid red !important;

} */
`;

export const ReactQuillStyled = styled(ReactQuill)`
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0px 4px 4px -2px #18274b14;
    box-shadow: 0px 2px 4px -2px #18274b1f;
    border: 1px solid #f1f1f1;

    .ql-toolbar {
        border: none;
    }
    .ql-container {
        border: none;
        height: 70%;
    }
`;

export const ReactQuillContainer = styled.div`
    height: ${p => p.height};
    padding: 0.5rem 0 2rem 0;
`;

export const Title = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

export const ContainerAnswer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 2rem;
    box-shadow: 0px 12px 24px -15px #0000001a;
    box-shadow: 0px 0px 10px -6px #00000040;
`;

export const Body = styled.div`
    position: relative;
    /* background-color: grey; */
    width: 100%;
`;


export const LoadingAnswers = styled.div`
    position: absolute;
    bottom: -10px;
    height: 15px;
    color: grey;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
`;