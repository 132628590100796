import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import CloseIcon from '@mui/icons-material/Close';
import EvaluationPreview from '../../pages/Resources/components/preview/EvaluationPreview';

export const ViewResource = ({ selectedClass, title, handleClose }) => {
    
    
    function getValidPublicationName(value) {
        const properties = ['nombre','publication_name', 'name',  'nombre_publicacion', 'title'];
        for (let prop of properties) {
            if (value[prop] 
                && value[prop].trim() !== '' 
                // && value[prop] !== 'undefined' 
                && value[prop] !== '-') {
                    return value[prop];
            }
        }
        return '';
    }

    const nombre_publicacion = getValidPublicationName(selectedClass);

    const formato = selectedClass?.formato ?? selectedClass?.type;

    const link_video = selectedClass?.link_video ?? selectedClass?.video_url;

    const link_storage = selectedClass?.link_storage ?? null;

    const renderTypeResources = () => {
        if (formato === 'evaluacion') {
            return <EvaluationPreview evaluation={link_video} />;
        } else if (formato === 'audiovisual') {
            return (
                <Video>
                    {link_video !== '' ? (
                        <ReactPlayerFake url={`${link_video}`} controls={true} width="100%" height="100%" playsinline />
                    ) : (
                        'No se puede visualizar el video'
                    )}
                </Video>
            );
        } else if (formato === 'material_plano') {
            return (
                <>
                    <HeaderResource>{nombre_publicacion || `${title}`}</HeaderResource>
                    <IframeMaterial>
                        {link_storage !== '' ? (
                            <Iframe src={`${link_storage}`.replace('view', 'preview')} frameBorder="0" allowFullScreen />
                        ) : (
                            'No se puede visualizar el material'
                        )}
                    </IframeMaterial>
                </>
            );
        } else if (formato === 'lectura') {
            return (
                <IframeMaterial>
                    {link_video !== '' ? (
                        <Iframe src={`${link_video}`.replace('view', 'preview')} frameBorder="0" allowFullScreen />
                    ) : (
                        'No se puede visualizar el material'
                    )}
                </IframeMaterial>
            );
        } else {
            return <h4>Tipo de recurso desconocido</h4>;
        }
    };

    return (
        <ViewRenderedResource>
            <CloseButton>
                <CloseIcon onClick={handleClose} />
            </CloseButton>
            <ContentResource overflow={formato === 'audiovisual' ? 'hidden' : 'auto'}>{renderTypeResources()}</ContentResource>
        </ViewRenderedResource>
    );
};

const ViewRenderedResource = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height: 80vh;
    width: 68vw;

    @media (width < 1600px) {
        width: 742px;
    }
`;

const CloseButton = styled.button`
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: #fff;
    top: -48px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;

    svg {
        color: #000;
    }
`;

const HeaderResource = styled.div`
    position: relative;
    max-height: 15%;
    align-items: center;
    text-align: center;
    padding: 1.5rem;
    position: relative;
    color: var(--secondary-one);
    font-weight: bold;
    line-height: 1rem;
    background-color: var(--primary-one);

    @media (max-width: 425px) {
        padding: 1rem;
    }
`;
const ContentResource = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    border-radius: 20px; /* Ajusta el valor según tus necesidades */
    /* overflow: hidden !important; */
    outline: 10px solid #c4c4c4;
    box-shadow: 0px 12px 24px -15px #0000001a, 0px 0px 10px -6px #00000040;
    overflow-y: auto;

    @media (max-width: 768px) {
        padding: 1rem;
    }
`;
const Video = styled.div`
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #f0f0f0;

    @media (max-width: 768px) {
        padding-bottom: 65%;
    }
`;
const ReactPlayerFake = styled(ReactPlayer)`
    width: 100%;
    height: 100%;
`;

const IframeMaterial = styled.div`
    position: relative;
    padding-bottom: 40%;
    padding-top: 33%;
    height: 100%;
    @media (max-width: 768px) {
        padding-bottom: 80%;
    }
`;

const Iframe = styled.iframe`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;
