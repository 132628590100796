export const resourcesAdapter = resourcesObjects => {
    return resourcesObjects.map(resourcesObject => ({
        id: resourcesObject.id,
        name: resourcesObject.name,
        title: resourcesObject.publication_name,
        video_url: resourcesObject.preview,
        image_url: resourcesObject.image,
        type: resourcesObject.type ? resourcesObject.type : 'audiovisual',
        courses: resourcesObject.assigned_courses
            ? resourcesObject.assigned_courses
            : []
        // courses:  [
        //           { id: 1, publication_name: 'WORKSHOP - Digital Operations Management' },
        //           { id: 2, publication_name: 'Los desafíos de la innovación: Change Management' },
        //           { id: 3, publication_name: 'Condiciones de Cursado (MKT-04-UNICA - E-mail Marketing)' },
        //           { id: 4, publication_name: 'Innovación y desarrollo de productos' },
        //       ],
    }));
};