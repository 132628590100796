import { Formik, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import MessageModal from '../../../../shared/common/MessageModal';
import {
    ButtonWithBackground,
    ButtonWithBorder,
    ContainerForm,
    ContentMaterialPlanoForm,
    FormContainer,
    IndicationTitle,
    Label,
    Second,
    WrapperButtons,
} from '../../styled-components/forms.styled.components';
import Text from '../../../../shared/common/Text';
import { createCustomResource, editCustomResource } from '../../services/resource.services';
import { AudiovisualFormSchema } from '../../../../utils/schemas';
import RadioGroupResources from '../RadioGroupResources';
import DefaultField from '../../../../shared/common/DefaultField';

const AudioVisualForm = props => {
    const { customResourceToEdit = null, ondemand_id, handleState, handleLoading } = props;

    let history = useHistory();

    //setea estado inicial de selectedType con el valor de customResourceToEdit?.subtype
    const typeAudiovisualReosurceToEdit = customResourceToEdit?.subtype === 'Youtube' ? 2 : customResourceToEdit?.subtype === 'Vimeo' ? 1 : null;

    // resource_subtype_id: 1 Vimeo / 2 Youtube,
    const resourcesSubtypeOption = [
        { id: 2, label: 'Youtube' },
        { id: 1, label: 'Vimeo' },
    ];

    const formik = useFormik({
        initialValues: {
            publication_name: customResourceToEdit !== null ? customResourceToEdit.publication_name : '',
            link_video: customResourceToEdit !== null ? customResourceToEdit.link_video : '',
            video_id: customResourceToEdit !== null ? customResourceToEdit.youtube_id || customResourceToEdit.vimeo_id : '',
        },
    });

    //STATES
    const [newResource, setNewResource] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);
    const [messageError, setMessageError] = useState(null);
    const [selectedSubtype, setSelectedSubtype] = useState(typeAudiovisualReosurceToEdit);

    //mensaje para recordar completar todos los campos del form
    const [messageOpen, setMessageOpen] = useState(false);

    //EFFECTS

    useEffect(() => {
        if (newResource !== null) {
            handleState(newResource);
        }
        //eslint-disable-next-line
    }, [newResource]);

    //FUNCTIONS
    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleSubmit = async values => {
        console.log('values :>> ', values);
        handleLoading(true);
        let data = '';
        const typeVideo = Number(selectedSubtype);
        if (typeVideo === 2) {
            data = {
                name: `ALAB_${values.publication_name}`,
                publication_name: values.publication_name,
                type: 'audiovisual',
                resource_subtype_id: 2,
                category_id: 21,
                date_deadline: '2024-12-31',
                audiovisual_data: {
                    time_duration: '0',
                    link_video: values.link_video,
                    youtube_data: {
                        youtube_id: values.video_id,
                    },
                },
            };
        } else if (typeVideo === 1) {
            data = {
                name: `ALAB_${values.publication_name}`,
                publication_name: values.publication_name,
                type: 'audiovisual',
                resource_subtype_id: 1, //vimeo
                category_id: 21,
                date_deadline: '2024-12-31',
                audiovisual_data: {
                    link_video: values.link_video,
                    time_duration: '0',
                    vimeo_data: {
                        vimeo_id: values.video_id,
                        direct_link_vimeo_1080: values.link_video,
                        direct_link_vimeo_720: values.link_video,
                    },
                },
            };
        }
        console.log('handleSubmit - data', data);
        let response =
            customResourceToEdit !== null 
            ? await editCustomResource(ondemand_id, customResourceToEdit.id, data) 
            : await createCustomResource(ondemand_id, data);
        console.log('response', response);
        setTimeout(() => {
            handleLoading(false);

            if (response.error) {
                setErrorOpen(true);
                setMessageError('El recurso no pudo enviarse, favor de contactarse con el administrador.');
            } else if (response.response_data.includes('ERROR')) {
                setErrorOpen(true);
                setMessageError(response.response_data);
            } else {
                setNewResource(response);
                handleState(response);
            }
        }, 3000);
    };
    const handleCompleted = () => {
        console.log('handleCompleted :>> ');
        setMessageOpen(true);
    };

    const handleDismiss = () => {
        history.push( customResourceToEdit !== null ? '/resources' : '/new-custom-resource');
    };

    function handleClose() {
        setMessageOpen(false);
    }

    //seleccion de type de Audiovisual
    function handleSubtypeSelected(type) {
        setSelectedSubtype(Number(type));
    }

    return (
        <ContainerForm>
            {!customResourceToEdit ? (
                <IndicationTitle>Creación de recurso audiovisual</IndicationTitle>
            ) : (
                <IndicationTitle>
                    <Text fontSize="0.8rem" fontWeight="400" lineheight="1.5rem" fontcolor="#535353">
                        Fecha y hora de última modificación del recurso:
                    </Text>
                    <Text fontSize="0.8rem" fontWeight="700" lineheight="1.5rem" fontcolor="#535353">
                        {customResourceToEdit?.last_change ? customResourceToEdit.last_change : '-' }
                    </Text>{' '}
                </IndicationTitle>
            )}

            <Formik 
            initialValues={formik.initialValues} 
            onSubmit={handleSubmit} 
            validationSchema={AudiovisualFormSchema}
            >
                {formik => (
                    <FormContainer>
                        <ContentMaterialPlanoForm>
                            <RadioGroupResources
                                label={'Formato del video'}
                                list={resourcesSubtypeOption}
                                resourceType={handleSubtypeSelected}
                                preSelected={selectedSubtype}
                            />

                            <Second>
                                <Label>
                                    Nombre del video (así se verá en la plataforma)
                                    <DefaultField
                                        name="publication_name"
                                        type="text"
                                        label=""
                                        required
                                        variant="outlined"
                                        placeholder=""
                                        border="1px solid #616161"
                                        borderradius="8px"
                                    />
                                </Label>

                                <Label>
                                    Enlace del video
                                    <DefaultField
                                        name="link_video"
                                        type="url"
                                        variant="outlined"
                                        label=""
                                        required
                                        placeholder="https://www.youtube.com/..."
                                        border="1px solid #616161"
                                        borderradius="8px"
                                    />
                                </Label>
                                <Label>
                                    Id de video
                                    <DefaultField
                                        name="video_id"
                                        type="string"
                                        variant="outlined"
                                        label=""
                                        required
                                        placeholder=""
                                        border="1px solid #616161"
                                        borderradius="8px"
                                    />
                                </Label>
                            </Second>
                        </ContentMaterialPlanoForm>

                        <WrapperButtons>
                            <ButtonWithBorder
                                disabled={!(formik.isValid && formik.dirty && selectedSubtype !== null)}
                                bordercolor={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="outlined"
                                type="button"
                                onClick={handleDismiss}>
                                Descartar Cambios
                            </ButtonWithBorder>
                            <ButtonWithBackground
                                bgcolor={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? '#A8A8A8' : 'var(--primary-one)'}
                                variant="contained"
                                type={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? 'button' : 'submit'}
                                onClick={!(formik.isValid && formik.dirty && selectedSubtype !== null) ? handleCompleted : null}>
                                Guardar Cambios
                            </ButtonWithBackground>
                        </WrapperButtons>
                     
                    </FormContainer>
                )}
            </Formik>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message="¡Ups!"
                image="/rediseño_2024/fallo_accion.png"
                subtitle="Recuerda que debes completar
                todos los campos para guardar
                el nuevo recurso."
            />
            <MessageModal
                isOpen={errorOpen}
                handleClose={handleCloseError}
                message="¡Ups!"
                image="/rediseño_2024/fallo_accion.png"
                subtitle={`${messageError}`}
            />
        </ContainerForm>
    );
};

export default AudioVisualForm;
