import React from 'react'
import styled from 'styled-components';
import Header from '../../shared/layout/header/Header';
import Layout from '../../shared/layout/Layout';
// import NewCourseForm from '../components/content-creation/NewCourseForm';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { getOA } from '../../redux/api/content-creation.api';
import EditCourseForm from '../../components/content-creation/EditCourseForm';
import { getBase64FromUrl } from '../../hooks/toBase64';
import MessageModal from '../../shared/common/MessageModal';

const EditCourse = () => {
    const { id } = useParams();
    let history = useHistory();
    const user = useSelector(state => state.user.user);

    //STATES
    const [customCourseToEdit, setCustomCourseToEdit] = useState(null);
    const [editedCustomCourse, setEditedCustomCourse] = useState(null); 
    const [publishedCustomCourse, setPublishedCustomCourse] = useState(null);

    const [messageOpen, setMessageOpen] = useState(false);
    const [contentMessage, setContentMessage] = useState(null);
    // getBase64FromUrl(`https://repositorio.aden.org/web/image?model=repo.aden.objeto_aprendizaje&field=imagen&id=1664`).then(console.log)
    

    //EFFECTS
    useEffect(() => {
        if (id !== null) {
            getCustomCourse(id);
        }
    }, [id]);

    useEffect(() => {
        if (!!editedCustomCourse) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Edición de curso guardada!',
                image: '/images/saved_article.svg',
                subtitle: 'La edición del curso se guardó como borrador',
            });
            getCustomCourse(id);

        }
    }, [editedCustomCourse]);


    useEffect(() => {
        if (!!publishedCustomCourse) {
            setMessageOpen(true);
            setContentMessage({
                message: '¡Curso publicado!',
                image: '/images/published_article.svg',
                subtitle: 'El curso fue publicado con éxito',
            });
            getCustomCourse(id);
        }
    }, [publishedCustomCourse]);


    //FUNCTIONS
    const getCustomCourse = async id => {
        let req = await getOA(id);
        console.log('req getCustomCourse', req)
        if (!!req) {
            setCustomCourseToEdit(req.response_data);
        }
    };

    const handlePublished = course => {
        setEditedCustomCourse(null);
        setPublishedCustomCourse(course);
    };

    const handleUpdate = course => {
        setEditedCustomCourse(course);
    };

    function handleClose() {
        setMessageOpen(false);
        setContentMessage(null);
        history.push(`/content?tab=cursos_empresa`);
    }

    return (
        <>
            <Layout header={<Header route="Edición de Curso" />}>
                <HeaderWrapper>
                    {!!customCourseToEdit && (
                        <EditCourseForm
                        customCourseToEdit={customCourseToEdit}
                        artId={id}
                        handleUpdate={handleUpdate}
                        handlePublished={handlePublished}
                        />
                    )}
                </HeaderWrapper>
            </Layout>
            <MessageModal
                isOpen={messageOpen}
                handleClose={handleClose}
                message={contentMessage?.message}
                image={contentMessage?.image}
                subtitle={contentMessage?.subtitle}
            />
        </>
    );
}

export default EditCourse;
const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    column-gap: 1.5rem;
    position: relative;
`;
