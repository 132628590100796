import axios from 'axios';

export function getNumberOfParticipants(ondemand_id) {
    return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/participantes_estado/${ondemand_id}`);
}

export function getParticipants({ ondemand_id }) {
    return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/participantes/${ondemand_id}`);
}

export async function createParticipant({ newParticipant, ondemand_id }) {
    return await axios
        .post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/crear_participante/${ondemand_id}`, newParticipant)
        .then(response => {
            // console.log(response);
            return {
                status: true,
                data: response.data,
            };
        })
        .catch(error => {
            // console.log(error);
            return {
                status: false,
                data: error,
            };
        });
}

export async function createParticipantList( body, ondemand_id ) {
    try {
        const request = await axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/creacion_masiva_participantes/${ondemand_id}`, body );
        return request.data;
    } catch (error) {
        return { error };
    }
}

export function removeParticipant({ id, ondemand_id }) {
    return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/eliminar_participante/${ondemand_id}`, { id: id });
}

//cambio de estado de activo <=> bloqueado
export function deactivateParticipant({ repositorio_id, status }) {
    return axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/acropolis/student_state/${repositorio_id}?state=${status}`);
}

export function assignCourse({ checkedItems, ondemand_id, course_id, es_obligatorio }) {
    return axios.post(
        `${process.env.REACT_APP_SISAPI_ONDEMAND}/matricular_participante/${ondemand_id}?obj_aprendizaje=${course_id}&es_obligatorio=${es_obligatorio}`,
        checkedItems,
    );
}

export function getParticipant(payload) {
    return axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/participante/${payload.ondemand_id}/${payload.user_id}`);
}

export async function getParticipantByOA({ ondemand_id, oa_id }) {
    try {
        const request = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/participantes/${ondemand_id}?obj_aprendizaje=${oa_id}`);
        return request.data;
    } catch (error) {
        return { error };
    }
}

export async function dienrrolParticipants(body, id, oa) {
    try {
        const request = await axios.post(`${process.env.REACT_APP_SISAPI_ONDEMAND}/desmatricular_participante/${id}?obj_aprendizaje=${oa}`, body);
        return request.data;
    } catch (error) {
        return { error };
    }
}

//ASSIGN SUBJECT

export function assignSubject({ checkedItems, ondemand_id, asignatura_id, es_obligatorio }) {
    return axios.post(
        `${process.env.REACT_APP_SISAPI_ONDEMAND}/matricular_participante/${ondemand_id}?asignatura_id=${asignatura_id}&es_obligatorio=${es_obligatorio}`,
        checkedItems,
    );
}

export async function getParticipantBySubject({ ondemand_id, asignatura_id }) {
    try {
        const request = await axios.get(`${process.env.REACT_APP_SISAPI_ONDEMAND}/participantes/${ondemand_id}?asignatura_id=${asignatura_id}`);
        return request.data;
    } catch (error) {
        return { error };
    }
}

export async function dienrrolParticipantsBySubject(body, id, asignatura_id) {
    try {
        const request = await axios.post(
            `${process.env.REACT_APP_SISAPI_ONDEMAND}/desmatricular_participante/${id}?asignatura_id=${asignatura_id}&es_obligatorio=false`,
            body,
        );
        return request.data;
    } catch (error) {
        return { error };
    }
}
