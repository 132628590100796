import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { FieldArray, Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { createArticle, getAllCategories } from '../../redux/api/blog';
import DefaultField from '../../shared/common/DefaultField';
import DefaultSelect from '../../shared/common/DefaultSelect';
import Snack from '../../shared/common/Snack';
import InputFile from './InputFile';
import PublicationInfo from './PublicationInfo';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import MDEditor from './MDEditor';
import { BlogFormSchema } from '../../utils/schemas';

const NewPostForm = props => {
    const { handleState, strapiId } = props;

    const user = useSelector(state => state.user.user);

    const [article, setArticle] = useState(null);
    const [categories, setCategories] = useState([]);
    const [file, setFile] = useState(null);
    const [mkdContent, setMkdContent] = useState(null);
    const [errorOpen, setErrorOpen] = useState(false);

    // EFFECTS
    useEffect(() => {
        if (categories.length === 0) {
            getCategories(user.id);
        }
    }, []);

    useEffect(() => {
        if (article !== null) {
            handleState(article);
        }
    }, []);

    // STATE INICIAL
    const initialValues = {
        title: '',
        preview: '',
        content: '',
        categories: [''],
        tags: [''],
        cover: '',
        source_link: '',
        is_external: true,
        main_category: '',
        company: strapiId,
        author: user.create_uid[1],
    };
    // FUNCTIONS

    const getCategories = async id => {
        let req = await getAllCategories(id);
        if (!!req) {
            setCategories(
                req.data.map(categ => ({
                    id: categ.id,
                    name: categ.attributes.name,
                })),
            );
        }
    };

    function handleCloseError() {
        setErrorOpen(false);
    }

    const handleContent = mkdCont => {
        setMkdContent(mkdCont);
    };

    const handleSubmitFile = file => {
        setFile(file);
    };

    const handleSubmit = async values => {
        const data = {
            title: values.title,
            preview: values.preview,
            source_link: null,
            is_external: false,
            author: user.create_uid[1],
            content: mkdContent,
            main_category: null,
            categories: values.categories,
            company: strapiId,
            tags: JSON.stringify(values.tags),
            published: false,
            cover_link: null,
        };
        const formData = new FormData();
        formData.append('data', JSON.stringify(data) || '');
        formData.append('files.cover', file || '');

        let response = await createArticle(formData);
        // console.log('response-sendArticle', response);
        if (response.error) {
            setErrorOpen(true);
            // console.log('responseERROR', response);
            <Snack open={errorOpen} handleClose={handleCloseError} severity="warning" color="#f44336">
                El artículo no pudo enviarse, favor de contactarse con el administrador.
            </Snack>;
        } else {
            // console.log('responseEXIT', response);
            setArticle(response);
            handleState(response);
        }
    };

    // console.log('mkdContent', mkdContent)
    // RETURN
    return (
        <>
            <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={BlogFormSchema}
            >
                {({ values }) => (
                    <FormContainer>
                        <Content>
                            <First>
                                <Row>
                                    <Label>
                                        Título
                                        <DefaultField
                                            name="title"
                                            type="text"
                                            label=""
                                            required
                                            variant="outlined"
                                            placeholder="Ingresa un título"
                                            border="1px solid #616161"
                                            borderradius="8px"
                                        />
                                    </Label>

                                    <Label>
                                        Slug
                                        <DefaultField
                                            name="slug"
                                            type="text"
                                            variant="outlined"
                                            disabled
                                            label=""
                                            placeholder="Campo autogenerado"
                                            border="1px solid #616161"
                                            borderradius="8px"
                                        />
                                    </Label>
                                </Row>
                                <Label>Descripción</Label>
                                <DefaultField
                                    name="preview"
                                    type="text"
                                    variant="outlined"
                                    label=""
                                    required
                                    placeholder="Ingresa una descripción breve "
                                    border="1px solid #616161"
                                    borderradius="8px"
                                />
                            </First>

                            <Second>
                                <Label>Información</Label>
                                <PublicationInfo author={initialValues.author} creation="-" update="-" published={false} />
                            </Second>

                            <Third>
                                <Label>Contenido</Label>
                                <MDEditor initialContent={initialValues.content} handleContent={handleContent} />
                            </Third>

                            <Fourth>
                                <Label>Categoría</Label>
                                <DefaultSelect name="categories" placeholder="Buscar" options={categories} />
                                <Label>Tags</Label>
                                <FieldArray
                                    name="tags"
                                    render={arrayHelpers => (
                                        <>
                                            {values.tags.map((tag, index) => (
                                                <Row key={index}>
                                                    <DefaultField
                                                        name={`tags.${index}`}
                                                        type="text"
                                                        label=""
                                                        variant="outlined"
                                                        placeholder="Ingresa tags para tu artìculo"
                                                        border="1px solid #616161"
                                                        borderradius="8px"
                                                    />
                                                    <Actions>
                                                        {index !== 0 && <DeleteRoundedIcon onClick={() => arrayHelpers.remove(index)} />}
                                                        <AddCircleOutlineRoundedIcon onClick={() => arrayHelpers.insert(index, '')} />
                                                    </Actions>
                                                </Row>
                                            ))}
                                        </>
                                    )}
                                />
                            </Fourth>

                            <Fifth>
                                <Label>Portada</Label>
                                <InputFile initialContent={initialValues.cover} handleSubmitFile={handleSubmitFile} />
                            </Fifth>
                        </Content>

                        <ButtonWrapper>
                            <WrapperButtons>
                                <ButtonToSave variant="contained" type="submit" disabled={mkdContent === null || file === null}>
                                    Guardar cambios
                                </ButtonToSave>
                            </WrapperButtons>
                        </ButtonWrapper>
                    </FormContainer>
                )}
            </Formik>
        </>
    );
};

export default NewPostForm;

const FormContainer = styled(Form)`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const ButtonWrapper = styled.div`
    margin: 1rem 0;
    display: flex;
    gap: 1rem;
`;

const Content = styled.div`
    display: grid;
    grid-template-areas:
        'first second'
        'third fourth'
        'third  fifth';
    gap: 2rem;
    margin-top: 25px;
    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }
`;

const Label = styled.span`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--primary-one);
    padding: 0.5rem 0 1rem 0;
`;

const First = styled.div`
    width: calc(100%-64px);
    grid-area: first;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Second = styled.div`
    grid-area: second;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Third = styled.div`
    grid-area: third;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Fourth = styled.div`
    grid-area: fourth;
    width: calc(100%-64px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Fifth = styled.div`
    grid-area: fifth;
    width: calc(100%-4rem);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
    background: #ffffff;
    box-shadow: 0px 6px 12px -6px rgba(24, 39, 75, 0.12), 0px 8px 24px -4px rgba(24, 39, 75, 0.08);
    border-radius: 20px;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 4fr 1fr;
    width: 100%;
    gap: 2rem;

    .MuiFormHelperText-root.MuiFormHelperText-contained.Mui-required.MuiFormHelperText-marginDense {
        display: none;
    }
`;

const Actions = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-around;
    svg {
        color: var(--secondary-four);
    }
`;

const WrapperButtons = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 0.5rem;
    position: absolute;
    top: -8rem;
    right: 1rem;
`;


const ButtonToSave = styled(Button)`
    &.MuiButtonBase-root.MuiButton-root {
        background-color: var(--primary-one);
        color: #ffff;
        padding: 0.75rem 1.5rem;
        gap: 0.5rem;
        width: max-content;
        border-radius: 3rem;
        span {
            font-size: 0.875rem;
            letter-spacing: 0.001em;
            line-height: 1.25rem;
            font-weight: 600;
        }
    }
`;
